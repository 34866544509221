<template>
  <oct-account-dialog
    :title="title"
    :text="text"
    class="ss-password-reset"
  >
    <oct-form>
      <oct-form-group>
        <oct-text-field
          name="email"
          type="email"
          :value="$route.query.email"
          placeholder="メールアドレス"
          disabled
        >
        </oct-text-field>
      </oct-form-group>

      <oct-form-group>
        <oct-text-field
          v-model="$data.$payload.password"
          type="password"
          placeholder="新しいパスワード"
          :validator="val => val.length <= 20 && /^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d]{8,}$/.test(val)"
          required
          ref="$_ssPasswordResetView_password"
        >
          <template #helperText>
            ※半角英数字8文字以上20文字以内で入力してください。<br>
            ※数字と英字を混ぜた文字列を設定してください。
          </template>

          <template #helperTextInvalid>
            入力されたパスワードは、同一文字の連続、文字数の過不足など、登録ができない形式です。
          </template>
        </oct-text-field>
      </oct-form-group>

      <oct-form-group>
        <oct-text-field
          v-model="$data.$payload.repassword"
          type="password"
          placeholder="新しいパスワード（確認）"
          :validator="val => val == $data.$payload.password"
          required
          ref="$_ssPasswordResetView_repassword"
        >
          <template #helperTextInvalid>
            パスワードが間違っています。
          </template>
        </oct-text-field>
      </oct-form-group>

      <template #primaryActions>
        <oct-button
          size="large"
          block
          :disabled="invalid"
          @click.native="submit"
        >
          変更
        </oct-button>
      </template>
    </oct-form>
  </oct-account-dialog>
</template>

<script>
import API from '@/api/index.js'
import OctButton from '@/components/button/OctButton.vue'
import OctTextField from '@/components/text-field/OctTextField.vue'
import OctForm from '@/components/form/OctForm.vue'
import OctFormGroup from '@/components/form/OctFormGroup.vue'
import OctAccountDialog from '@/components/account-dialog/OctAccountDialog.vue'
import OctPrototypeForm from '@/mixins/OctPrototypeForm.js'

export default {
  name: 'SSPasswordResetView',
  mixins: [
    OctPrototypeForm
  ],
  components: {
    OctButton,
    OctTextField,
    OctForm,
    OctFormGroup,
    OctAccountDialog
  },
  props: {
    /** Page title */
    title: {
      type: String,
      default: 'パスワード再設定'
    },
    /** Body text */
    text: {
      type: String,
      default: ''
    },
    /** Payload */
    payload: {
      type: Object,
      default: () => ({
        passoword: '',
        repassword: ''
      })
    }
  },
  methods: {
    /** Submit handler */
    beforeSubmit () {
      return API[/\/signup/.test(this.$route.path) ? 'signUp' : 'resetPassword'](this.$data.$payload.password, this.$route.query.code)
        .then(() => this.$router.push({name:'PasswordReset.done'}).catch(() => {}))
        .catch(() => this.$store.commit('error', {message: 'パスワードを設定できませんでした'}))
    },
  },
}
</script>

<style scoped lang="scss">
</style>