<template>
  <oct-account-dialog
    :title="title"
    class="ss-signup-view"
  >
    <oct-form>
      <oct-form-group
        label="サロン名"
      >
        <oct-text-field
          disabled
          :value="salonName"
        />
      </oct-form-group>

      <oct-form-row>
        <oct-form-col :cols="2">
          <oct-form-group
            label="姓"
          >
            <oct-text-field
              placeholder="田中"
              required
              v-model="$data.$payload.lastName"
              ref="$_octUF_lastName"
            >
              <template #helperTextInvalid>
                入力してください。
              </template>
            </oct-text-field>
          </oct-form-group>
        </oct-form-col>

        <oct-form-col :cols="2">
          <oct-form-group
            label="名"
          >
            <oct-text-field
              placeholder="太郎"
              required
              v-model="$data.$payload.firstName"
              ref="$_octUF_firstName"
            >
              <template #helperTextInvalid>
                入力してください。
              </template>
            </oct-text-field>
          </oct-form-group>
        </oct-form-col>
      </oct-form-row>

      <oct-form-group
        label="アカウント種別"
      >
        <oct-select
          block
          outline
          v-model="$data.$payload.role"
          :items="[
            {
              value: 2,
              text: 'スタイリスト'
            },
            {
              value: 3,
              text: 'アシスタント'
            }
          ]"
        />
      </oct-form-group>

      <oct-form-group
        label="メールアドレス"
      >
        <oct-text-field
          placeholder="wada@sstock.milbon.co.jp"
          type="email"
          required
          v-model="$data.$payload.email"
          ref="$_octUF_email"
        >
          <template #helperTextInvalid>
            メールアドレスが正しくありません。
          </template>
        </oct-text-field>
      </oct-form-group>

      <!-- Admin status -->
      <oct-form-group>
        <oct-checkbox
          required
          v-model="$data.$payload.useSalonToken"
        />

        <span
          class="ss-signup-view__caption-text"
        >
          <a href="https://salon.milbon.co.jp/pdf/TermsOfUse_STYLESTOCK.pdf" target="_blank">
            利用規約に
            <oct-icon
              icon="launch"
            />
          </a>
          同意する
        </span>
      </oct-form-group>

      <template #primaryActions>
        <oct-button
          size="large"
          block
          :disabled="invalid"
          @click.native="submit"
        >
          送信
        </oct-button>
      </template>
    </oct-form>
  </oct-account-dialog>
</template>

<script>
import API from '@/api/index.js'
import OctIcon from '@/components/icon/OctIcon.vue';
import OctButton from '@/components/button/OctButton.vue'
import OctCheckbox from '@/components/checkbox/OctCheckbox.vue'
import OctTextField from '@/components/text-field/OctTextField.vue'
import OctSelect from '@/components/select/OctSelect.vue'
import OctForm from '@/components/form/OctForm.vue'
import OctFormRow from '@/components/form/OctFormRow.vue'
import OctFormCol from '@/components/form/OctFormCol.vue'
import OctFormGroup from '@/components/form/OctFormGroup.vue'
import OctAccountDialog from '@/components/account-dialog/OctAccountDialog.vue'
import OctPrototypeView from '@/mixins/OctPrototypeView.js'
import OctPrototypeForm from '@/mixins/OctPrototypeForm.js'

export default {
  name: 'SSSignUpView',
  mixins: [
    OctPrototypeView,
    OctPrototypeForm
  ],
  components: {
    OctIcon,
    OctButton,
    OctCheckbox,
    OctTextField,
    OctSelect,
    OctForm,
    OctFormRow,
    OctFormCol,
    OctFormGroup,
    OctAccountDialog
  },
  props: {
    /** Page title */
    title: {
      type: String,
      default: ''
    },
    /** Salon name */
    salonName: {
      type: String,
      default: ''
    },
    /** Salon Token */
    token: {
      type: String,
      default: ''
    },
    /** Payload */
    payload: {
      type: Object,
      default: () => ({
        salonId: ''
      })
    }
  },
  methods: {
    /** Submit handler */
    async beforeSubmit () {
      try {
        await API.signUpWithToken(this.$data.$payload, this.token)
        this.$router.push({name:'SignUp.sent'})
      } catch {
        this.$store.commit('error', {message: 'アカウントを新規登録できませんでした。しばらく経ってからもう一度やり直してください。'})
      }
    },
  },
  watch: {
    /**
     * Watch payload and validate on change
     * @event update:payload
     */
    '$data.$payload': {
      handler (val) {
        if (!val.useSalonToken)
          this.invalid = true
      },
      deep: true
    },
  }
}
</script>

<style scoped lang="scss">
@import "../components/theme/variables";
@import "../components/grid/variables";
@import "../components/typography/variables";
@import "../components/typography/mixins";

.ss-signup-view__caption-text {
  font-size: oct-rem(16);
  line-height: 1;
  display: inline-flex;
  align-items: center;
  gap: oct-rem(6);
  padding-left: oct-rem(8);

  a {
    text-decoration: underline;
    color: inherit;
    display: inline-flex;
    gap: oct-rem(3);
    align-items: center;
  }

  ::v-deep .oct-icon {
    width: oct-rem(16);
    height: oct-rem(16);
  }
}
</style>